import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Header from "./Header/Header";
import Hero from "./LandingPage/Hero/Hero";
import { Fragment } from "react";
import Services from "./LandingPage/Services/Services";
import JoinUs from "./LandingPage/JoinUs/JoinUs";
import { ToastContainer } from "react-toastify";
import CustomerReview from "./LandingPage/CustomerReview/CustomerReview";
import CustomerReviewCarousel from "./LandingPage/Slider/CustomerReviewCarousel";
import Footer from "./Footer/Footer";
import LandingForm from "./Form/LandingForm";
import UserLogin from "./AuthForms/UserLogin";
import UserRegsiter from "./AuthForms/UserRegister";
import ForgotPassword from "./AuthForms/ForgotPassword";
import ServicesMain from "./Services/Services";
import JoinUsRegsiterForm from "./AuthForms/JoinUsRegisterForm";
import JoinUsServiceDetails from "./AuthForms/JoinUsServiceDetails";
import Search from "./SearchResults/Search";
import UserDashboard from "./Dashboards/UserDashboard";
import ProviderLogin from "./AuthForms/ProviderLogin";
import ProviderDashboard from "./Dashboards/ProviderDashboard";
import AboutUs from "./AboutUs/AboutUs";
import ContactUs from "./ContactUs/ContactUs";
import JoinUsMain from "./JoinUs/JoinUs";
import ChatWidget from "./ChatWidget/ChatWidget";
import ProviderProfile from "../pages/ProviderProfile/ProviderProfile";
import { useAppSelector } from "../app/hooks";
import PageLoader from "./PageLoader/Loader";
import Protected from "./Protected/Protected";
import InitialLoader from "./PageLoader/InitialLoader";
import AuthProtected from "./AuthProtected/AuthProtected";
import ContactProvider from "../pages/ContactProvider/ContactProvider";
import DogBreedSelect from "./DogBreedSelect/DogBreedSelect";
import ResetPassword from "./AuthForms/ResetPassword";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import Admin from "../pages/Admin/Admin";
import AdminDoctorsList from "../pages/AdminUser/AdminUser";
import AdminProviderList from "../pages/AdminProvider/AdminProviderList";
function Navigation() {
    const user = useAppSelector((state) => state.user);
    const navigate = useNavigate();
    const style = {
        margin: 0,
        top: "auto",
        right: 20,
        bottom: 20,
        left: "auto",
        position: "fixed",
        padding: "1rem 2rem",
        backgroundColor: "#035772",
        color: "#FFF",
    };

    return (
        <>
            <ToastContainer style={{ fontSize: "20px" }} />
            <Routes>
                <Route path="/" element={
                    <React.Fragment>
                        <Header />
                        <Hero />
                        {user.type !== 'provider' && <LandingForm />}
                        <Services />
                        <JoinUs />
                        <CustomerReview />
                        <CustomerReviewCarousel />
                        <ChatWidget />
                        <Footer />
                    </React.Fragment>
                } />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/search" element={<Search />} />
                <Route path="/services" element={
                    <>
                        <Header />
                        <ServicesMain />
                    </>
                } />
                <Route path="/joinus" element={<JoinUsMain />} />
                <Route path="/admin/dashboard" element={<Protected />}>
                    <Route index element={<Admin />} />
                    {<Route path="users">
                        <Route index element={<AdminDoctorsList />} />
                    </Route>

                    }
                    {<Route path="providers">
                        <Route index element={<AdminProviderList />} />
                    </Route>
                    }
                    {/*

                    <Route path="doctors">
                        <Route index element={<AdminDoctorsList />} />
                        <Route path=":doctorId" element={<AdminDoctors />} />
                    </Route>

                    <Route path="hospitals">
                        <Route index element={<AdminHospitalList />} />
                        <Route path=":hospitalId" element={<AdminHospital />} />
                    </Route> */}
                </Route>
                <Route path="/auth" element={<AuthProtected />}>
                    <Route path="userlogin" element={
                        <React.Suspense fallback={<InitialLoader />}>
                            <UserLogin />
                        </React.Suspense>
                    } />
                    <Route path="providerlogin" element={
                        <React.Suspense fallback={<InitialLoader />}>
                            <ProviderLogin />
                        </React.Suspense>
                    } />
                    <Route path="adminlogin" element={
                        <React.Suspense fallback={<InitialLoader />}>
                            <UserLogin />
                        </React.Suspense>
                    } />
                </Route>
                <Route path="/" element={<Protected />}>
                    {user.type === "user" && (
                        <Route path="user/dashboard" element={
                            <React.Suspense fallback={<PageLoader />}>
                                <UserDashboard />
                            </React.Suspense>
                        } />
                    )}
                    {user.type === "provider" && (
                        <Route path="provider/dashboard" element={
                            <React.Suspense fallback={<PageLoader />}>
                                <ProviderDashboard />
                            </React.Suspense>
                        } />
                    )}
                    <Route path="contact/provider/:id" element={
                        <React.Suspense fallback={<PageLoader />}>
                            <ContactProvider />
                        </React.Suspense>
                    } />
                </Route>
                <Route path="/forgotPassword" element={<AuthProtected />}>
                    <Route path=":type" element={<ForgotPassword />} />
                </Route>
                <Route path="/reset-password/:type/:token" element={<ResetPassword />} />
                <Route path="/joinus/register/:joinAs" element={<JoinUsRegsiterForm />} />
                <Route path="/joinus/register/:joinAs/serviceDetails" element={<JoinUsServiceDetails />} />
                <Route path="/search/provider/:id" element={<ProviderProfile />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </>
    );
}

export default Navigation;
