import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageLoader from "../PageLoader/Loader";
import { toast } from "react-toastify";

function Protected() {
    const location = useLocation();
    console.log(location);
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        if (!user.isLoggedIn) {
            navigate("/", { replace: true });
            toast.error("please login to", {
                position: "top-right",
            });
        }
        setLoading(false);
    }, [user]);

    return loading ? <PageLoader /> : <Outlet />;
}

export default Protected;
