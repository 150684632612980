import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import styles from "./Header.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { userActions } from "../../features/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Logo from "./logo.svg";
import axiosInstance from "../../api/axiosInstance";
const Header = () => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [url, setUrl] = useState(null);
    const [anchorEl, setAnchorEl] = useState(false);

    const logoutHandler = async () => {
        try {
            await axiosInstance.get("/users/logout");
            handleClose();
            dispatch(userActions.reset());
            navigate("/");
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    return (
        <Fragment>
            {["lg"].map((expand) => (
                <Navbar
                    key={expand}
                    bg="light"
                    expand={expand}
                    className={`${styles.nav}`}
                >
                    <Navbar.Brand as={Link} to="/" className={styles.navBrand}>
                        <img src={Logo} alt="icon" style={{ scale: "1.4" }} />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                lineHeight: "1",
                                justifyContent: "center",
                            }}
                        >
                            <p style={{ margin: "0", fontSize: "2.4rem" }}>
                                PETLEVERT
                            </p>
                            <span
                                style={{ fontSize: "0.9rem", color: "black" }}
                            >
                                Connecting Pets And People
                            </span>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls={`offcanvasNavbar-expand-${expand}`}
                        className={styles.navToggler}
                    />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                        className={styles.navToggler}
                    >
                        <Offcanvas.Header closeButton className={styles.close}>
                            <Offcanvas.Title
                                id={`offcanvasNavbarLabel-expand-${expand}`}
                                className={styles.offCanvesTitle}
                            >
                                Freskie
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav
                                className={`justify-content-end flex-grow-1 pe-3 ${styles.container}`}
                            >
                                <Nav.Link
                                    as={Link}
                                    to="/"
                                    className={`${styles.navLink} + ${url === "/" ? styles.active : ""
                                        }`}
                                >
                                    Home
                                </Nav.Link>
                                {user.type !== 'provider' &&
                                    <Nav.Link
                                        as={Link}
                                        to="/services"
                                        className={`${styles.navLink} + ${url === "/services" ? styles.active : ""
                                            }`}
                                    >
                                        Services
                                    </Nav.Link>
                                }
                                <Nav.Link
                                    as={Link}
                                    to="/joinus"
                                    className={`${styles.navLink} + ${url === "/joinus" ? styles.active : ""
                                        }`}
                                >
                                    Join Us
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to="/aboutus"
                                    className={`${styles.navLink} + ${url === "/aboutus" ? styles.active : ""
                                        }`}
                                >
                                    About Us
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to="/contact"
                                    className={`${styles.navLink} + ${url === "/contact" ? styles.active : ""
                                        }`}
                                >
                                    Contact
                                </Nav.Link>
                                {user.isLoggedIn && (
                                    <div>
                                        <Button
                                            aria-describedby={id}
                                            variant="contained"
                                            onClick={handleClick}
                                            className={styles.userIcon}
                                        >
                                            <span style={{ fontSize: "1rem" }}>
                                                {user.name[0]}
                                            </span>
                                        </Button>
                                        <Popover
                                            id={id}
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                        >
                                            <Typography
                                                className={styles.test}
                                                sx={{ padding: "1rem 6rem" }}
                                            >
                                                <Nav.Link
                                                    as={Link}
                                                    to={`/${user.type}/dashboard`}
                                                    className={styles.dropdownLink}
                                                >
                                                    Profile
                                                </Nav.Link>
                                            </Typography>
                                            <Typography
                                                className={styles.test}
                                                sx={{ padding: "1rem 6rem" }}
                                            >
                                                <button
                                                    className={styles.logoutButton}
                                                    onClick={logoutHandler}
                                                >
                                                    Logout
                                                </button>
                                            </Typography>
                                        </Popover>
                                    </div>
                                )}
                                {!user.isLoggedIn && (
                                    <div>
                                        <Button
                                            aria-describedby={id}
                                            variant="contained"
                                            onClick={handleClick}
                                            className={styles.userIcon}
                                        >
                                            <AccountCircleIcon sx={{ fontSize: 28 }} />{" "}
                                            Login
                                        </Button>
                                        <Popover
                                            id={id}
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                        >
                                            <Typography
                                                sx={{ padding: "1rem 6rem" }}
                                                className={styles.test}
                                            >
                                                <Nav.Link as={Link} to="/auth/userlogin">
                                                    {<PersonAddIcon />} User Login
                                                </Nav.Link>
                                            </Typography>
                                            <Typography
                                                sx={{ padding: "1rem 6rem" }}
                                                className={styles.test}
                                            >
                                                <Nav.Link
                                                    as={Link}
                                                    to="/auth/providerlogin"
                                                >
                                                    {<ManageAccountsIcon />} Provider Login
                                                </Nav.Link>
                                            </Typography>
                                        </Popover>
                                    </div>
                                )}
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Navbar>
            ))
            }
        </Fragment >
    );
};

export default Header;
