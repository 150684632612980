import React from 'react';
import NotFoundImage from './404.jpg';
import styles from "./PageNotFound.module.css";
const PageNotFound = () => {
    return (
        <div className={styles["not-found-container"]}>
            <img src={NotFoundImage} alt="404 Not Found" className={styles["not-found-image"]} />
        </div>
    );
};

export default PageNotFound;
