import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { Outlet, useLocation, Navigate, useNavigate } from "react-router-dom";
import InitialLoader from "../PageLoader/InitialLoader";
import { toast } from "react-toastify";
function AuthProtected() {
    const location = useLocation();
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        if (user.isLoggedIn) {
            navigate("/");
            toast.warn("Already logged In", { position: "top-right" })
        }
        setLoading(false);
    }, []);

    return loading ? <InitialLoader /> : <Outlet />;
}

export default AuthProtected;
